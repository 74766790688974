import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { Logo } from '../LandingPage/Logo'

import { getCurrentUser } from '../../services/authService'
import './payment.css'

import visa from '../../media/visa.svg'
import american from '../../media/american-express.svg'
import discover from '../../media/discover.svg'
import mastercard from '../../media/mastercard.svg'
import payme from '../../media/payme.png'
import click from '../../media/click.jpeg'
import uzcard from '../../media/uzcard.png'

function Skeleton() {
	return (
		<div className="animate-pulse">
			<div className="h-12 bg-gray-300 rounded"></div>
			<div className="mt-4 h-8 bg-gray-300 rounded"></div>
			<div className="mt-4 h-8 bg-gray-300 rounded"></div>
			<div className="mt-4 h-8 bg-gray-300 rounded"></div>
		</div>
	)
}

function Payment() {
	const [selectedPlan, setSelectedPlan] = useState('yearly')
	const [isLoading, setIsLoading] = useState(false)
	const [loading, setLoading] = useState(true)
	const [paymentMethod, setPaymentMethod] = useState('visa_mastercard')
	const [invoiceData, setInvoiceData] = useState(null)

	const user = getCurrentUser()
	const history = useNavigate()

	const paymentOptions = [
		{ id: 'visa_mastercard', label: 'Credit Card', detail: 'Credit Card' },
		{ id: 'uzcard', label: 'UZCARD', detail: 'UZCARD' },
	]

	const handlePlanChange = event => {
		setSelectedPlan(event.target.value)
	}

	const handlePaymentMethodChange = event => {
		setPaymentMethod(event.target.value)

		console.log('event.target.value', event.target.value)
	}

	const handleSubscription = async () => {
		setIsLoading(true)

		const planType = selectedPlan

		try {
			const response = await fetch('/api/stripe/create-checkout-session', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					planType,
					customerEmail: user?.email,
				}),
			})

			if (!response.ok) throw new Error('Network response was not ok.')

			const session = await response.json()

			window.location.href = session?.url
			setIsLoading(false)
		} catch (error) {
			console.error('Error during subscription process:', error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		const isPaid = invoiceData?.subscription?.periodEnd

		if (isPaid) {
			window.location = '/settings'
		}
	}, [invoiceData, history])

	useEffect(() => {
		const fetchInvoice = async () => {
			try {
				setLoading(true)
				const jwt = localStorage.getItem('jwtToken')
				const userJwt = jwtDecode(jwt)

				const clientEmail = userJwt?.email

				const invoiceData = await axios.get(`/api/stripe/checkInvoice`, {
					params: { customerEmail: clientEmail },
				})

				if (invoiceData.data) {
					setInvoiceData(invoiceData.data)
				}
				setLoading(false)
			} catch (error) {
				console.log('error', error)
				setLoading(false)
			}
		}

		fetchInvoice()
	}, [])

	const usedTrial = invoiceData?.subscription?.hasUsedTrial

	const handleBackClick = () => {
		window.location.href = '/loadboard'
	}

	return (
		<div className="flex justify-center py-10 gap-x-10 w-full min-h-screen bg-[#f1f4f9]">
			<div className="hidden lg:block md:w-2/4 lg:w-1/3 p-8 bg-[#f1f4f9]">
				<div className="-mt-14 mb-12">
					<Logo />

					{user && (
						<div className="ml-10 -mt-3 text-gray-700 font-semibold font-light italic tracking-wide">
							{user.email}
							<p
								onClick={handleBackClick}
								className="text-blue-600 hover:text-blue-700 cursor-pointer mt-2"
							>
								&lt; Back
							</p>
						</div>
					)}
				</div>

				<h2 className="text-xl font-semibold mb-6 text-gray-800">
					What You'll Get with the Pro Plan
				</h2>
				<ul className="list-none space-y-4 mb-6">
					<li className="text-md flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Post and delete truck orders with one click for streamlined
						operations.
					</li>
					<li className="flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Access Load History to review past transactions and optimize
						strategies.
					</li>
					<li className="flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Check for Road Restrictions to stay ahead of any obstacles on the
						route.
					</li>
					<li className="flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Get Real-time ETA updates for in-transit trips to manage
						expectations.
					</li>
					<li className="flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Utilize the Driver Gross Revenue Calculator to forecast earnings.
					</li>
					<li className="flex items-center text-gray-700">
						<span className="text-green-500 mr-2">✓</span>
						Check for Bobtailed loads prior to booking.
					</li>
				</ul>
			</div>

			<div className="w-full h-auto lg:w-2/6 bg-white p-8 my-8 mx-6 rounded-lg shadow-lg">
				<div className="bg-white p-5">
					{loading ? (
						<Skeleton />
					) : (
						<>
							<div className="flex items-start mb-4">
								<div className="flex items-center">
									<input
										type="radio"
										id="yearly"
										name="plan"
										value="yearly"
										className="form-radio h-5 w-5 text-blue-600"
										onChange={handlePlanChange}
										checked={selectedPlan === 'yearly'}
									/>
									<label
										htmlFor="yearly"
										className="ml-2 text-md font-semibold text-gray-700"
									>
										<span>Paid yearly</span>
										<span className="ml-2 text-xs text-blue-600 border rounded-md p-1 whitespace-nowrap">
											SAVE 50%
										</span>
										<span className="font-normal block text-sm font-semibold text-gray-500">
											$24.50 USD / month
										</span>
									</label>
								</div>

								<div className="ml-10 flex items-center">
									<input
										type="radio"
										id="monthly"
										name="plan"
										value="monthly"
										className="form-radio h-5 w-5 text-blue-700"
										onChange={handlePlanChange}
										checked={selectedPlan === 'monthly'}
									/>
									<label
										htmlFor="monthly"
										className="ml-1 text-md font-semibold text-gray-600"
									>
										Paid monthly
										<span className="font-normal block text-sm font-semibold text-gray-500">
											$49.00 USD / month
										</span>
									</label>
								</div>
							</div>

							<div className="border-t border-dashed border-gray-300"></div>

							<div className="bg-white p-6">
								<div className="mb-0">
									<span className="font-normal block text-sm font-semibold text-gray-500">
										Order Summary
									</span>
								</div>

								<div className="flex justify-between items-start mb-4">
									<span className="block text-md font-semibold text-gray-900">
										Today's Total:
									</span>

									<div className="">
										{selectedPlan === 'yearly' ? (
											<div>
												<span className="block text-md text-indigo-500 font-semibold">
													{/* {!usedTrial
														? '$0.00 for 7-day free trial'
														: '$294.00 / year'} */}
													$294.00 / year
												</span>
												<span className="block text-xs text-gray-500">
													{/* {!usedTrial && 'After 7 days: '} */}
													{/* $294.00 USD / year */}
												</span>
												<span className="block text-xs text-gray-500">
													$24.50 x 12 months
												</span>
											</div>
										) : (
											<div>
												<span className="block text-md text-indigo-500 font-semibold">
													$49.00 USD / month
												</span>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="border-t border-dashed border-gray-300 mb-8"></div>

							<div className="payment-methods">
								{paymentOptions.map(option => (
									<label
										key={option.id}
										className={`payment-method ${
											paymentMethod === option.id && 'payment-method-active'
										}`}
										htmlFor={option.id}
									>
										<input
											type="radio"
											id={option.id}
											name="paymentMethod"
											value={option.id}
											checked={paymentMethod === option.id}
											onChange={handlePaymentMethodChange}
											className="hidden"
										/>
										<div className="payment-method-content">
											<div className="payment-method-text">{option.label}</div>
											{option.id === 'visa_mastercard' && (
												<div className="payment-icons">
													<img src={visa} alt="Visa" className="payment-icon" />
													<img
														src={mastercard}
														alt="MasterCard"
														className="payment-icon"
													/>
													<img
														src={american}
														alt="American Express"
														className="payment-icon"
													/>
													<img
														src={discover}
														alt="Discover"
														className="payment-icon"
													/>
												</div>
											)}

											{option.id === 'uzcard' && (
												<div className="payment-icons">
													<img
														src={payme}
														alt="PayMe"
														className="payment-icon uzcard"
													/>
													<img
														src={click}
														alt="Click"
														className="payment-icon uzcard"
													/>
													<img
														src={uzcard}
														alt="UzCard"
														className="payment-icon uzcard"
													/>
												</div>
											)}
										</div>
									</label>
								))}
							</div>

							<div className="flex flex-col items-center justify-center p-6">
								{paymentMethod === 'visa_mastercard' ? (
									<button
										onClick={handleSubscription}
										className={`text-white font-bold py-2 ${
											selectedPlan === 'yearly' ? 'px-8' : 'px-8'
										} rounded transition duration-300 ease-in-out bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg transform hover:scale-105`}
										disabled={isLoading}
									>
										{isLoading ? (
											<div className="flex items-center justify-center">
												<svg
													className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
												>
													<circle
														className="opacity-25"
														cx="12"
														cy="12"
														r="10"
														stroke="currentColor"
														strokeWidth="4"
													></circle>
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 0116 0H4z"
													></path>
												</svg>
												Submitting...
											</div>
										) : selectedPlan === 'yearly' && !usedTrial ? (
											'Buy Now'
										) : (
											'Buy Now'
										)}
									</button>
								) : (
									<a
										href="https://t.me/loadfetcher_support"
										target="_blank"
										rel="noopener noreferrer"
										className="text-white font-bold py-2 px-8 rounded transition duration-300 ease-in-out bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg transform hover:scale-105"
									>
										Contact Us
									</a>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default Payment
